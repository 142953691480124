/**
 * 产品品牌信息列表
 */
import Vue from "vue";
import { Page } from "iview";
Vue.component("Page", Page);
import ProductMenu from "@/components/ProductMenu/productMenu.vue";
import {
  getShopGroupService,
  getBrandCategoryService,
  getBrandCategoryConfService,
  getBrandCreateAllService
} from "@/service/spu-group-service";
import { getMarketProductListService } from "@/service/market-service";
import { SOURCE_NAME, sortBy } from "@/service/public/enum";
import { mapMutations, mapState } from "vuex";
import $event, { EVENT_NAME } from "@/utils/event-emitter";
import VueLazyLoad from "vue-lazyload";
import Cookies from "js-cookie";
import { onCharacterPrice, isPhone, callBackTop } from "@/utils/tools";
import { addGoodsCollectionService } from "@/service/goods-collection-service";
import CustomSelect from "@/components/CustomSelect/customSelect.vue";
Vue.use(VueLazyLoad, {
  loading: require("@/assets/Loading/seize-seat-img.jpg")
});
const BrandsList = {
  name: "BrandsList",
  components: {
    ProductMenu,
    CustomSelect
  },
  computed: {
    ...mapState({
      language: (state) => state.language.code, //获取状态语言
      currencyCode: (state) => state.system.currencyCode, //获取状态管理币种代码
      source: (state) => state.product.source, //获取状态管理来源
      menuId: (state) => state.product.menuId, //获取状态管理菜单id
      brandId: (state) => state.product.brandId, //获取状态管理品牌id
      menuType: (state) => state.product.menuType, //获取状态管理一级分类
      secondType: (state) => state.product.secondType, //获取状态管理二级分类
      tertiaryType: (state) => state.product.tertiaryType, //获取状态管理三级分类
      levelType: (state) => state.product.levelType, //获取状态管理四级分类
      selectionId: (state) => state.product.selectionId, //获取状态管理选择id
      isNew: (state) => state.product.isNew, //获取状态管理新款
      isSale: (state) => state.product.isSale, //获取状态管理折扣
      isOld: (state) => state.product.isOld, //获取状态管理古着
      label: (state) => state.product.label, //获取状态管理标签
      level: (state) => state.product.level, //获取状态管理标签
      isParent: (state) => state.product.isParent, //获取状态管理是否是子级
      showMenu: (state) => state.product.showBrandFilter, //获取状态管理是否筛选品牌
      brandDetail: (state) => state.product.brandDetail, //获取状态管理品牌详情
    }),
    onStyle() {
      return (key, isBanner) => {
        if (key === 0 || key === 1 || key === 2 || key === 3) {
          return "margin-top";
        }
        if (isBanner === 1) {
          return "advert-li";
        }
      };
    }
  },
  data() {
    return {
      onCharacterPrice: onCharacterPrice,
      loadingVisible: false,
      sortVisible: false, //排序弹框
      selectSort: {}, //选中的排序
      sortList: sortBy, //排序数据集
      productList: {}, //商品列表对象
      productSelect: "", //选中的商品
      productType: [], //商品属性对象
      productClassify: [], //商品分类对象
      productBrands: [], //商品品牌对象
      productNewIn: [], //商品折扣新款古着
      productSize: [], //商品尺码
      brandCategory: [], //品牌信息数据集
      brandCategoryConf: [], //品牌信息标签数据集
      brandConfSelect: [], //品牌信息标签选中状态
      sizeName: "", //尺码名称
      menuStyle: "",
      barStyle: "",
      productParams: {
        classifySelect: "", //选择分类
        brandsSelect: "", //选择品牌
        attributeIds: "", //选择的属性
        specsIds: "", //选择的尺码
        prices: [], //选择的价格
        currentPage: 1,
        pageSize: 70
      },
      queryObj: {},
      isPhone: isPhone
    };
  },
  created() {
    callBackTop()
    this.selectSort = {
      id: "",
      name: this.$t("list.sortBy")
    };
    // this.onProductType()
    /**
     * 订阅商品菜单事件
     */
    $event.$on(EVENT_NAME.PRODUCT_BRANDS_MENU_QUERY, () => {
      this.productType = [];
    });
    /**
     * 订阅商品列表事件名
     */
    $event.$on(EVENT_NAME.PRODUCT_BRANDS_LIST_QUERY, () => {
      // this.onProductType()
      this.productType = [];
      this.productClassify = [];
      this.productBrands = [];
      this.productNewIn = [];
      this.productSize = [];
      this.productParams.prices = [];
      this.loadingVisible = true;
      this.productParams.currentPage = 1;
      this.onProductService();
      document.documentElement.scrollTop = document.body.scrollTop = 0; //定位滚动条到头部
    });
    this.loadingVisible = true;
    this.onProductService();
    // !this.productType.includes(this.threeCategory.id)?this.productType.push(this.threeCategory):null
    // this.productType.type = this.threeCategory
  },
  mounted() {
    if (window.innerWidth > 540) {
      document.documentElement.scrollTop = document.body.scrollTop = 0; //定位滚动条到头部
      this.menuStyle = "height:" + (window.innerHeight - 149) + "px";
      window.addEventListener("scroll", this.handleScroll);
      window.addEventListener("resize", this.menuStyleFunction, true);
    }
  },
  methods: {
    ...mapMutations("product", [
      "SET_PRODUCT_ID",
      "SET_IS_NEW",
      "SET_IS_SALE",
      "SET_IS_OLD",
      "SET_BRAND_FILTER"
    ]),
    menuStyleFunction() {
      this.menuStyle = "height:" + (window.innerHeight - 149) + "px";
    },
    handleScroll() {
      this.scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scrollTop > 0) {
        this.barStyle = "top:104px; position: sticky;";
      } else {
        this.barStyle = "top:0; position: relative;";
      }
    },
    /**
     * 选中标签
     * @param id
     */
    onBrandCheck(id) {
      if (!Cookies.get("token")) {
        $event.$emit(EVENT_NAME.HEADER_TIPS_STATUS);
        return;
      }
      if (this.brandConfSelect.indexOf(id) > -1) {
        this.brandConfSelect.splice(this.brandConfSelect.indexOf(id), 1);
      } else {
        this.brandConfSelect.push(id);
      }
      for (let i in this.brandCategoryConf) {
        if (id === this.brandCategoryConf[i].cid1) {
          this.brandCategoryConf[i].check === 1
            ? (this.brandCategoryConf[i].check = 0)
            : (this.brandCategoryConf[i].check = 1);
        }
      }
      this.onBrandCreateAll().then();
    },
    /**
     * 全选/取消全选
     */
    onBrandAll() {
      if (!Cookies.get("token")) {
        $event.$emit(EVENT_NAME.HEADER_TIPS_STATUS);
        return;
      }
      if (
        this.brandConfSelect.length === 0 ||
        this.brandConfSelect.length !== this.brandCategoryConf.length
      ) {
        this.brandConfSelect = [];
        for (let i in this.brandCategoryConf) {
          this.brandCategoryConf[i].check = 1;
          this.brandConfSelect.push(this.brandCategoryConf[i].cid1);
        }
      } else {
        for (let i in this.brandCategoryConf) {
          this.brandCategoryConf[i].check = 0;
        }
        this.brandConfSelect = [];
      }
      this.onBrandCreateAll().then();
    },
    /**
     * 排序
     * @param obj
     */
    onSort(obj) {
      this.selectSort = obj;
      this.sortVisible = false;
      this.loadingVisible = true;
      this.onProductService();
    },
    /**
     * 删除属性筛选条件
     * @param id
     */
    onCloseScreen(id) {
      this.loadingVisible = true;
      for (let i in this.productType) {
        if (id === this.productType[i].id) {
          this.productType.splice(i, 1);
        }
      }
      let obj = {
        id: id,
        name: ""
      };
      $event.$emit(EVENT_NAME.COMMODITY_ATTRIBUTE_QUERY, obj);
      this.onProductService();
    },
    /**
     * 删除分类筛选条件
     * @param id
     */
    onCloseClassify(id) {
      this.loadingVisible = true;
      for (let i in this.productClassify) {
        if (id === this.productClassify[i].id) {
          this.productClassify.splice(i, 1);
        }
      }
      let obj = {
        id: id,
        name: this.$t("menu.category")
      };
      $event.$emit(EVENT_NAME.COMMODITY_ATTRIBUTE_QUERY, obj);
      this.onProductService();
    },
    /**
     * 删除品牌筛选条件
     * @param id
     */
    onCloseBrands(id) {
      this.loadingVisible = true;
      for (let i in this.productBrands) {
        if (id === this.productBrands[i].id) {
          this.productBrands.splice(i, 1);
        }
      }
      let obj = {
        id: id,
        name: this.$t("menu.brands")
      };
      $event.$emit(EVENT_NAME.COMMODITY_ATTRIBUTE_QUERY, obj);
      this.onProductService();
    },
    /**
     * 删除尺码
     * @param id
     */
    onCloseSize(id) {
      this.loadingVisible = true;
      for (let i in this.productSize) {
        if (id === this.productSize[i].id) {
          this.productSize.splice(i, 1);
        }
      }
      let obj = {
        id: id,
        name: this.$t("menu.size")
      };
      $event.$emit(EVENT_NAME.COMMODITY_ATTRIBUTE_QUERY, obj);
      this.onProductService();
    },
    /**
     * 删除折扣新款古着
     * @param id
     */
    onCloseNewIn(id) {
      this.loadingVisible = true;
      for (let i in this.productNewIn) {
        if (id === this.productNewIn[i].id) {
          this.productNewIn.splice(i, 1);
        }
      }
      let obj = {
        id: id,
        name: this.$t("menu.newIn")
      };
      if (id === 407 || id === 411) {
        this.SET_IS_NEW(0);
      }
      if (id === 408 || id === 412) {
        this.SET_IS_SALE(0);
      }
      if (id === 409) {
        this.SET_IS_OLD(0);
      }
      $event.$emit(EVENT_NAME.COMMODITY_ATTRIBUTE_QUERY, obj);
      this.onProductService();
    },
    /**
     * 删除价格筛选条件
     * @param id
     */
    onClosePrice() {
      this.loadingVisible = true;
      this.productParams.prices = [];
      this.onProductService();
    },
    /**
     * 属性筛选条件
     */
    onSelectAttribute(obj) {
      this.loadingVisible = true;
      if (this.productType.length === 0) {
        this.productType.push(obj);
      } else {
        let index = this.productType.findIndex((val) => val.id === obj.id);
        if (this.productType.findIndex((val) => val.id === obj.id) !== -1) {
          this.productType.splice(index, 1);
        } else {
          this.productType.push(obj);
        }
      }
      this.onProductService();
    },
    /**
     * 分类筛选条件
     */
    onSelectType(obj) {
      this.loadingVisible = true;
      if (this.productClassify.length === 0) {
        this.productClassify.push(obj);
      } else {
        let index = this.productClassify.findIndex((val) => val.id === obj.id);
        if (this.productClassify.findIndex((val) => val.id === obj.id) !== -1) {
          this.productClassify.splice(index, 1);
        } else {
          this.productClassify.push(obj);
        }
      }
      this.onProductService();
    },
    /**
     * 品牌筛选条件
     * @param obj
     */
    onSelectBrands(obj) {
      this.loadingVisible = true;
      if (this.productBrands.length === 0) {
        this.productBrands.push(obj);
      } else {
        let index = this.productBrands.findIndex((val) => val.id === obj.id);
        if (this.productBrands.findIndex((val) => val.id === obj.id) !== -1) {
          this.productBrands.splice(index, 1);
        } else {
          this.productBrands.push(obj);
        }
      }
      this.onProductService();
    },
    /**
     * 新款古着折扣条件筛选
     * @param obj
     */
    onSelectNewIn(obj) {
      this.loadingVisible = true;
      if (this.productNewIn.length === 0) {
        this.productNewIn.push(obj);
      } else {
        let index = this.productNewIn.findIndex((val) => val.id === obj.id);
        if (this.productNewIn.findIndex((val) => val.id === obj.id) !== -1) {
          this.productNewIn.splice(index, 1);
        } else {
          this.productNewIn.push(obj);
        }
      }

      this.onProductService();
    },
    /**
     * 尺码条件筛选
     * @param obj
     */
    onSelectSize(obj) {
      this.loadingVisible = true;
      let list = obj.obj;
      this.sizeName = obj.name;
      if (this.productSize.length === 0) {
        this.productSize.push(list);
      } else {
        let index = this.productSize.findIndex((val) => val.id === list.id);
        if (this.productSize.findIndex((val) => val.id === list.id) !== -1) {
          this.productSize.splice(index, 1);
        } else {
          this.productSize.push(list);
        }
      }
      this.onProductService();
    },
    /**
     * 价格
     * @param price
     */
    onPrice(price) {
      this.loadingVisible = true;
      this.productParams.prices = price;
      this.onProductService();
    },
    /**
     * 根据来源调用不同商品数据
     */
    onProductService() {
      switch (this.source) {
        case SOURCE_NAME.SUPER_SALE: //超卖
          this.onMarketProductList().then();
          this.onBrandCategory().then();
          this.onBrandCategoryConf().then();
          break;
        case SOURCE_NAME.MENU_ADVERT: //广告
          this.onMarketProductList().then();
          this.onBrandCategory().then();
          this.onBrandCategoryConf().then();
          break;
        case SOURCE_NAME.MENU_LINK_LIST: //header下拉菜单
          this.onProductList().then();
          this.onBrandCategory().then();
          this.onBrandCategoryConf().then();
          break;
      }
    },
    /**
     * 点击喜爱
     */
    onLike(obj) {
      if (!Cookies.get("token")) {
        $event.$emit(EVENT_NAME.HEADER_TIPS_STATUS);
        return;
      }
      this.onGoodsCollection(obj.id, obj.isWish).then();
    },
    /**
     * 跳转到详情页
     * @param id
     */
    onQueryDetails(id) {
      this.SET_PRODUCT_ID(id);
      this.$router.push("/product/details?id=" + id);
    },
    /**
     * 广告跳转
     * @param obj
     */
    onAdvertLink(obj) {
      window.open(obj.bannerDto.link, "_blank");
    },
    /**
     * 发送当前页
     * @param number
     */
    onSearchPage(number) {
      this.loadingVisible = true;
      this.productParams.currentPage = number;
      this.onProductService();
      document.documentElement.scrollTop = document.body.scrollTop = 0; //定位滚动条到头部
    },
    /**
     * 发送每页显示的条数
     * @param number
     */
    onSearchPageSize(number) {
      this.loadingVisible = true;
      this.productParams.pageSize = number;
      this.onProductService();
    },
    /**
     * 拉取特卖商品列表
     * @returns {Promise<void>}
     */
    async onProductList() {
      try {
        if (this.loadingVisible) {
          this.productList = {};
        }
        this.productParams.attributeIds = "";
        for (let i in this.productType) {
          this.productParams.attributeIds += this.productType[i].id + ",";
        }
        this.productParams.classifySelect = "";
        for (let i in this.productClassify) {
          this.productParams.classifySelect += this.productClassify[i].id + ",";
        }
        this.productParams.brandsSelect = "";
        for (let i in this.productBrands) {
          this.productParams.brandsSelect += this.productBrands[i].id + ",";
        }
        this.productParams.specsIds = "";
        for (let i in this.productSize) {
          this.productParams.specsIds += this.productSize[i].id + ",";
        }
        let params = {
          brandIds: this.brandId,
          attributeIds: this.productParams.attributeIds,
          price2:
            this.productParams.prices.length !== 0
              ? this.productParams.prices[0] +
                "," +
                this.productParams.prices[1]
              : "",
          specs: this.productParams.specsIds,
          cid1: this.menuType,
          cid2: "",
          cid3: "",
          cid4: "",
          cid5: "",
          isSale: this.isSale,
          isNew: this.isNew,
          isOld: this.isOld,
          sortBy: this.selectSort.id,
          size: this.productParams.pageSize,
          page: this.productParams.currentPage,
          status: 0
        };
        if (this.level == 2) {
          params.cid2 = this.menuId;
        }
        if (this.level == 3) {
          if (this.isParent === 0) {
            params.cid3 = this.productParams.classifySelect;
          } else {
            params.cid3 = this.menuId;
          }
        }
        if (this.level == 4) {
          if (this.isParent === 0) {
            params.cid4 = this.productParams.classifySelect;
          } else {
            params.cid4 = this.menuId;
          }
        }
        if (this.level == 5) {
          if (this.isParent === 0) {
            params.cid5 = this.productParams.classifySelect;
          } else {
            params.cid5 = this.menuId;
          }
        }
        const data = await getShopGroupService(params);
        this.productList = data;
        this.loadingVisible = false;
      } catch (error) {
        this.loadingVisible = false;
        console.log(error);
      }
    },
    /**
     * 查询市场活动分组商品列表
     * @returns {Promise<void>}
     */
    async onMarketProductList() {
      try {
        this.loadingVisible = true;
        this.productList = {};
        this.productParams.attributeIds = "";
        for (let i in this.productType) {
          this.productParams.attributeIds += this.productType[i].id + ",";
        }
        this.productParams.classifySelect = "";
        for (let i in this.productClassify) {
          this.productParams.classifySelect += this.productClassify[i].id + ",";
        }
        this.productParams.brandsSelect = "";
        for (let i in this.productBrands) {
          this.productParams.brandsSelect += this.productBrands[i].id + ",";
        }
        this.productParams.specsIds = "";
        for (let i in this.productSize) {
          this.productParams.specsIds += this.productSize[i].id + ",";
        }
        let params = {
          brandIds: this.brandId,
          attributeIds: this.productParams.attributeIds,
          price2:
            this.productParams.prices.length !== 0
              ? this.productParams.prices[0] +
                "," +
                this.productParams.prices[1]
              : "",
          specs: this.productParams.specsIds,
          id: this.selectionId,
          cid1: this.menuType,
          cid2: this.secondType.categoryId,
          cid3: this.tertiaryType.categoryId,
          cid4: "",
          cid5: "",
          isSale: this.isSale || 0,
          isNew: this.isNew || 0,
          isOld: this.isOld || 0,
          sortBy: this.selectSort.id,
          size: this.productParams.pageSize,
          page: this.productParams.currentPage,
          status: 0
        };
        const data = await getMarketProductListService(params);
        this.productList = data;
        this.loadingVisible = false;
      } catch (error) {
        this.loadingVisible = false;
        console.log(error);
      }
    },
    /**
     * 新增商品收藏
     * @returns {Promise<void>}
     */
    async onGoodsCollection(id, type) {
      try {
        // this.loadingVisible = true
        // this.productList = {}
        let params = {
          productId: id,
          type: type === 0 ? 1 : 0
        };
        const { code } = await addGoodsCollectionService(params);
        if (code === 1) {
          for (let i in this.productList.content) {
            if (id === this.productList.content[i].id) {
              //isWish
              type === 0
                ? (this.productList.content[i].isWish = 1)
                : (this.productList.content[i].isWish = 0);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 查询商品组品牌列表信息
     * @returns {Promise<void>}
     */
    async onBrandCategory() {
      try {
        this.brandCategory = [];
        let params = {
          brandId: this.brandId,
          cid1: this.menuType
        };
        const { code, data } = await getBrandCategoryService(params);
        if (code === 1) {
          this.brandCategory = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 查询商品组品牌列表信息标签
     * @returns {Promise<void>}
     */
    async onBrandCategoryConf() {
      try {
        let params = {
          brandId: this.brandId,
          categoryId: this.menuType
        };
        const { code, data } = await getBrandCategoryConfService(params);
        if (code === 1) {
          this.brandCategoryConf = data;
          this.brandConfSelect = [];
          for (let i in this.brandCategoryConf) {
            if (this.brandCategoryConf[i].check === 1) {
              this.brandConfSelect.push(this.brandCategoryConf[i].cid1);
            }
            if (this.brandCategoryConf[i].cid1Name === 'Home Fragrance' || this.brandCategoryConf[i].cid1Name === '香氛') {
              const obj = this.brandCategoryConf[i]
              this.brandCategoryConf.splice(i, 1)
              this.brandCategoryConf.splice(4, 0, obj)
            }
            if (this.brandCategoryConf[i].cid1Name === 'Nursery' || this.brandCategoryConf[i].cid1Name === '婴幼儿用品') {
              const obj = this.brandCategoryConf[i]
              this.brandCategoryConf.splice(i, 1)
              this.brandCategoryConf.splice(this.brandCategoryConf.length, 0, obj)
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 查询商品组品牌列表信息标签选中
     * @returns {Promise<void>}
     */
    async onBrandCreateAll() {
      try {
        let brandConfSelect = "";
        for (let i in this.brandConfSelect) {
          brandConfSelect += this.brandConfSelect[i] + ',';
        }
        let params = {
          brandId: Number(this.brandId),
          categoryIds: brandConfSelect
        };
        const { code } = await getBrandCreateAllService(params);
        if (code === 1) {
          console.log();
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  /**
   * 销毁事件
   */
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    $event.$off([
      EVENT_NAME.PRODUCT_BRANDS_LIST_QUERY,
      EVENT_NAME.PRODUCT_BRANDS_MENU_QUERY
    ]);
    this.SET_BRAND_FILTER(false)
  }
};
export default BrandsList;
