<template>
  <div class="product-body">
    <!--s: Product Bar-->
    <div class="product-bar" v-if="!isPhone()">
      <span class="product-type">{{ label }}</span>
      <div class="product-bar-sort">
        <CustomSelect
          v-model="sortVisible"
          :title="selectSort ? selectSort.name : $t('list.sortBy')"
          placement="right"
        >
          <div class="bar-sort">
            <ul>
              <li
                :key="'bar' + key"
                v-for="(sort, key) in sortList"
                v-show="selectSort.id !== sort.id"
              >
                <a href="javascript:" @click="onSort(sort)">{{ sort.name }}</a>
              </li>
            </ul>
          </div>
        </CustomSelect>
      </div>
    </div>
    <!--e: Product Bar-->

    <div class="ctn">
      <!-- h5 -->
      <h1 class="product-title">{{ label }}</h1>
      <div class="product-list-filter" v-show="!showMenu">
        <a
          href="javascript:"
          :style="
            sortVisible || (selectSort && selectSort.name !== $t('list.sortBy'))
              ? 'background:#fff'
              : ''
          "
          :class="sortVisible ? 'active' : ''"
          @click="
            sortVisible = !sortVisible;
            SET_BRAND_FILTER(false);
          "
          >{{ selectSort ? selectSort.name : $t("list.sortBy") }}</a
        >
        <a
          href="javascript:"
          @click="
            SET_BRAND_FILTER(!showMenu);
            sortVisible = false;
          "
          :class="showMenu ? 'active' : ''"
          >{{ $t("list.filter") }}</a
        >
        <div class="product-sort-box" v-if="sortVisible">
          <a
            href="javascript:"
            :key="'bar' + key"
            v-for="(sort, key) in sortList"
            v-show="selectSort.id !== sort.id"
            @click="onSort(sort)"
            >{{ sort.name }}</a
          >
          <a href="javascript:" @click="sortVisible = false" class="close"></a>
        </div>
      </div>
      <!-- h5 -->
      <!--s: Menu-->
      <div
        class="product-menu"
        v-if="!isPhone() || showMenu"
        id="menuHeight"
        :style="menuStyle"
      >
        <div class="product-select-type">
          <div class="box">
            <span
              class="type-name"
              :key="'productType' + key"
              v-for="(product, key) in productType"
              @click="onCloseScreen(product.id)"
              >{{ product.name }} <i class="close"></i
            ></span>
            <span
              class="type-name"
              :key="'productClassify' + key"
              v-for="(classify, key) in productClassify"
              @click="onCloseClassify(classify.id)"
              >{{ classify.name }} <i class="close"></i
            ></span>
            <span
              class="type-name"
              :key="'productBrands' + key"
              v-for="(brands, key) in productBrands"
              @click="onCloseBrands(brands.id)"
              >{{ brands.name }} <i class="close"></i
            ></span>
            <span
              class="type-name"
              :key="'productNewIn' + key"
              v-for="(newIn, key) in productNewIn"
              @click="onCloseNewIn(newIn.id)"
              >{{ newIn.name }} <i class="close"></i
            ></span>
            <span
              class="type-name"
              :key="'productSize' + key"
              v-for="(size, key) in productSize"
              @click="onCloseSize(size.id)"
              >{{ sizeName }} {{ size.value }} <i class="close"></i
            ></span>
            <!--                    <span class="type-name"  @click="onClosePrice" v-if="productParams.prices.length!==0">{{ language==='CN'?'¥':'$' }}{{ productParams.prices[0] }} - {{ language==='CN'?'¥':'$' }}{{ productParams.prices[1] }} <i class="close"></i></span>-->
          </div>
          <a href="javascript:" class="collection"></a>
        </div>
        <!--s: Menu Box-->
        <ProductMenu
          brand
          @on-select-attribute="onSelectAttribute"
          @on-select-type="onSelectType"
          @on-select-brands="onSelectBrands"
          @on-price="onPrice"
          @on-select-new-in="onSelectNewIn"
          @on-select-size="onSelectSize"
        />
        <!--e: Menu Box-->
      </div>
      <!--e: Menu-->
      <div v-if="isPhone() && showMenu" class="product-list-filter">
        <a href="javascript:" @click="onClearFilter">{{
          $t("list.clearAll")
        }}</a>
        <a href="javascript:" @click="SET_BRAND_FILTER(!showMenu)">{{
          $t("list.showResult")
        }}</a>
      </div>

      <div>
        <!--s: List-->
        <div
          class="product-list"
          :style="
            (productList.content && productList.content.length === 0) ||
            loadingVisible
              ? 'border-color:#fff'
              : ''
          "
        >
          <div class="product-brands-info">
            <img :src="brandCategory.coverImage" class="brands-img" />
            <div class="brands-right">
              <h1 class="title">{{ brandCategory.name }}</h1>
              <div class="info" v-show="brandCategory.content">
                {{ brandCategory.content }}
              </div>
              <div class="bottom">
                <h2 class="tips-title" v-if="brandCategoryConf.length !== 0">
                  {{ $t("list.brandTitle") }}
                </h2>
                <div class="list">
                  <div class="label-wrapper">
                    <a
                      href="javascript:"
                      class="label"
                      :class="item.check === 1 ? 'selected' : ''"
                      @click="onBrandCheck(item.cid1)"
                      :key="key"
                      v-for="(item, key) in brandCategoryConf"
                      :style="
                        menuType === 4 && (key === 5 || key === 6) && isPhone()
                          ? 'width:2.95rem'
                          : menuType === 3 && key === 6
                          ? 'margin-left:0.91rem'
                          : (key === 4 || key === 11) && menuType !== 3
                          ? isPhone()
                            ? 'width:4.85rem'
                            : 'width:149px'
                          : ''
                      "
                      >{{ item.cid1Name }}</a
                    >
                    <a
                      href="javascript:"
                      class="like-box"
                      v-if="brandCategoryConf.length !== 0"
                      :class="
                        brandConfSelect.length === brandCategoryConf.length
                          ? 'like'
                          : 'like-grey'
                      "
                      @click="onBrandAll"
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ul>
            <li
              class="list-li"
              :class="onStyle(key, productList.isBanner)"
              :key="'list' + key"
              v-for="(productList, key) in productList.content"
              :style="
                productList.id === productSelect
                  ? 'background-color:#e5e5e5'
                  : ''
              "
            >
              <div class="list-handle" v-if="productList.isBanner !== 1">
                <a
                  href="javascript:"
                  class="handle-unified like"
                  @click="onLike(productList)"
                  :class="productList.isWish === 1 ? 'like' : 'like-grey'"
                ></a>
                <a
                  href="javascript:"
                  class="handle-unified sole star"
                  v-if="productList.isSole === 1"
                ></a>
                <a
                  href="javascript:"
                  class="handle-unified draft draft"
                  v-if="productList.isShow === 1"
                ></a>
              </div>
              <div class="list-text" v-if="productList.isBanner !== 1">
                <img
                  class="list-img"
                  @click="onQueryDetails(productList.id)"
                  v-lazy="productList.images"
                  :src="productList.images"
                />
                <div class="list-box">
                  <div class="list-status">
                    <span class="is-new" v-if="productList.isNew === 1">{{
                      $t("menu.isNew")
                    }}</span>
                    <span class="is-sale" v-if="productList.isSale === 1">{{
                      $t("menu.isSale")
                    }}</span>
                  </div>
                  <span class="box-text brand">{{
                    productList.brandName
                  }}</span>
                  <span class="box-text">{{ productList.name }}</span>
                  <span class="box-text bold">{{
                    currencyCode === "cny"
                      ? onCharacterPrice(productList.price)
                      : productList.price
                  }}</span>
                  <!--                            <span class="box-text">{{ productList.productId }}</span>-->
                </div>
              </div>
              <div class="content" v-if="productList.isBanner === 1">
                <img
                  class="content-img"
                  @click="onAdvertLink(productList)"
                  v-lazy="productList.bannerDto.coverImage"
                  :src="productList.bannerDto.coverImage"
                />
                <div class="content-box">
                  <h1 class="box-title">
                    {{ productList.bannerDto.coverTitle }}
                  </h1>
                  <span class="box-text">{{
                    productList.bannerDto.coverSubtitle
                  }}</span>
                </div>
              </div>
            </li>
          </ul>
          <div class="product-loading" v-if="loadingVisible"></div>
          <div
            class="product-no-data"
            v-if="productList.content && productList.content.length === 0"
          >
            {{ $t("list.noData") }}
          </div>
        </div>
        <!--e: List-->
        <!--s: page-->
        <div
          class="product-page"
          v-if="productList.content && productList.content.length !== 0"
        >
          <Page
            :total="productList.totalElements"
            :current="productParams.currentPage"
            :page-size="productParams.pageSize"
            @on-change="onSearchPage"
            @on-page-size-change="onSearchPageSize"
            size="small"
          ></Page>
        </div>
        <!--e: page-->
      </div>
    </div>
  </div>
</template>
<script>
import BrandsList from "./brandsList";
export default BrandsList;
</script>
<style lang="less">
@import "brandsList";
</style>
